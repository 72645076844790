import React from "react";
import EmployeeThumbs from "./Images/Employees/EmployeeThumbs";

const Intro = () => {
  return (
    <div className="intro flex-initial">
      <EmployeeThumbs />
      <h1 className=" text-3xl font-medium font-sans mb-1">
        Welkom bij <span className="text-blue-600 font-bold">CMC</span> 👋
      </h1>
      <span className="block mb-10 leading-5">
        CMC begeleidt bedrijven met de beste IT- en telecomdiensten en
        garandeert succesvolle digitale transformaties.
      </span>
    </div>
  );
};

export default Intro;
