import React, { useEffect, useState } from "react";
import Select from "react-select";
import Cookies from "universal-cookie";
import i18next from "i18next";

export const LanguageSelector = () => {
  const [lang, setLang] = useState("");
  const cookies = new Cookies();

  const options = [
    { value: "NL", label: "NL" },
    { value: "FR", label: "FR" },
    { value: "EN", label: "EN" },
  ];

  const changeLang = (lng: string) => {
    i18next.changeLanguage(lng);
  };

  const handleChange = (event: any) => {
    setLang(event.value);
    changeLang(event.value);
    cookies.set("lang", event.value, { path: "/" });
  };

  useEffect(() => {
    if (!cookies.get("lang")) {
      cookies.set("lang", "NL", { path: "/" });
      setLang("NL");
    } else {
      setLang(cookies.get("lang"));
    }

    if (cookies.get("lang") != i18next.language) {
      changeLang(cookies.get("lang"));
    }
  }, []);

  return (
    <Select
      options={options}
      onChange={handleChange}
      placeholder={lang}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? "#1d4ed8" : "#d1d5db",
        }),
      }}
    />
  );
};